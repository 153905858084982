import { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function DatePicker({ date, setState }) {
  const [focused, setFocused] = useState();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <SingleDatePicker
      required
      id="date"
      date={date}
      onOutsideClick
      showClearDate={!!date}
      numberOfMonths={mobile ? 1 : 2}
      onDateChange={(date) => setState((state) => ({ ...state, date }))}
      focused={focused}
      onFocusChange={({ focused }) => setFocused(focused)}
      displayFormat={() => 'DD/MM/YYYY'}
      isDayBlocked={(day) => [0, 6].includes(day.weekday())}
      showDefaultInputIcon
    />
  );
}
