import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import useStats from '../hooks/useStats';
import LoadingSpinner from './LoadingSpinner';
import StatCard from './StatCard';

export default function Stats() {
  const { isLoading, isError, data: stats, error } = useStats();

  if (isLoading) return <LoadingSpinner />;

  if (isError) return <Typography>Error: {error.message}</Typography>;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h5" style={{ fontWeight: 'bold' }}>
          Cover Stats for {stats.school.name}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <StatCard
          avatar={<EventBusyIcon style={{ verticalAlign: 'middle' }} />}
          title="Staff absences"
          statKey="absences"
          values={[
            { subtitle: 'Past month', value: stats.absences[0] },
            { subtitle: 'Past year', value: stats.absences[1] },
            { subtitle: 'All time', value: stats.absences[2] },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <StatCard
          avatar={<MenuBookIcon style={{ verticalAlign: 'middle' }} />}
          title="Cover work set (%)"
          statKey="cover"
          values={[
            { subtitle: 'Past month', value: stats.workSet[0] },
            { subtitle: 'Past year', value: stats.workSet[1] },
            { subtitle: 'All time', value: stats.workSet[2] },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <StatCard
          avatar={<ThumbUpIcon style={{ verticalAlign: 'middle' }} />}
          title="Average feedback score"
          statKey="feedback"
          values={[
            {
              subtitle: 'Past month',
              value: stats.averageFeedbackScores[0],
            },
            {
              subtitle: 'Past year',
              value: stats.averageFeedbackScores[1],
            },
            {
              subtitle: 'All time',
              value: stats.averageFeedbackScores[2],
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}
