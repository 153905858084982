import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Alert from '@material-ui/lab/Alert';
import CoverRequestDialog from '../components/CoverRequestDialog';
import BrowseCoverDialog from './BrowseCoverDialog';
import NewAbsenceDialog from './NewAbsenceDialog';
import CoverCardList from './CoverCardList';

function EmptyState({ isManagerRequired }) {
  return (
    <Alert severity="info">
      {isManagerRequired
        ? "You haven't requested any cover yet."
        : "You haven't set any cover work yet."}
    </Alert>
  );
}

export default function RequestedCover({ items, isManagerRequired }) {
  const [isCoverDialogOpen, setCoverDialogOpen] = useState(false);
  const [isBrowseDialogOpen, setBrowseDialogOpen] = useState(false);
  return (
    <div style={{ margin: '25px 0' }}>
      <CoverRequestDialog
        isOpen={isCoverDialogOpen && isManagerRequired}
        handleClose={() => setCoverDialogOpen(false)}
        isManagerRequired={isManagerRequired}
      />
      <NewAbsenceDialog
        open={isCoverDialogOpen && !isManagerRequired}
        handleClose={() => setCoverDialogOpen(false)}
        isManagerRequired={isManagerRequired}
      />
      <BrowseCoverDialog
        isOpen={isBrowseDialogOpen}
        handleClose={() => setBrowseDialogOpen(false)}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          margin: '10px 0',
          gap: 5,
        }}
      >
        <Typography component="h2" variant="h5" style={{ fontWeight: 'bold' }}>
          {isManagerRequired ? 'Requested/Delegated Cover' : 'Cover Lessons'}
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 10,
          }}
        >
          {!isManagerRequired && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
              onClick={() => setBrowseDialogOpen(true)}
            >
              Search cover work
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => setCoverDialogOpen(true)}
            startIcon={<AddIcon />}
            style={{
              color: '#fff',
              backgroundColor: 'forestgreen',
            }}
          >
            {isManagerRequired ? 'Request cover' : 'Set cover work'}
          </Button>
        </div>
      </div>
      {!items?.length && <EmptyState isManagerRequired={isManagerRequired} />}
      <CoverCardList items={items} />
    </div>
  );
}
