import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function DepartmentalAbsencesHelpDialog({ open, handleClose }) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="help-dialog-title"
      open={open}
    >
      <DialogTitle id="help-dialog-title" onClose={handleClose}>
        Departmental Absences
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          You are seeing this because you have been named as the line manager
          for one or more members of staff. From here, you are able to get a
          quick overview of any upcoming cover for your department, including
          whether work has been set, allowing you to prepare learning materials
          etc in advance.
        </Typography>
        <Typography gutterBottom>
          In addition, you will also receive a daily cover summary email each
          morning at 8am Monday-Friday. This email outlines any cover for the
          day and includes links to view the cover work which has been set.
        </Typography>
        <Typography gutterBottom>
          Please note that only the next 7 days of departmental absences are
          visible at any given time. Cover lessons planned beyond that timeframe
          will not show up until nearer the time.
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
