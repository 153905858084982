import moment from 'moment';
import { useState } from 'react';
import { useRouter } from 'next/router';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LaunchIcon from '@material-ui/icons/Launch';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import RoomIcon from '@material-ui/icons/Room';
import GetAppIcon from '@material-ui/icons/GetApp';
import AvailabilityDialog from './AvailabilityDialog';
import { exportMultiplePdfs } from '../utils/exportPdf';

function EmptyState() {
  return (
    <Alert severity="info">
      You haven&apos;t been allocated any cover yet. We will notify you if this
      changes.
    </Alert>
  );
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
});

function CoverCard({ item }) {
  const router = useRouter();
  const classes = useStyles();
  const roomCode = item.tempRoomCode || item.entry.roomCode;
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {moment(item.startDateTime).format('ddd DD MMM')}
          </Typography>
          {roomCode && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <RoomIcon style={{ marginRight: 5 }} />
              <Typography>Room {roomCode}</Typography>
            </div>
          )}
        </div>
        <Typography variant="h5" component="h2">
          {item.entry.class.name}
        </Typography>
        <Typography color="textSecondary">
          Period{' '}
          {item.entry.timetablePeriod
            ? item.entry.timetablePeriod.name.split(':')[1]
            : `${item.entry.startDateTime.toLocaleString()}
          - ${item.entry.endDateTime.toLocaleString()}`}
          &bull;{' '}
          {moment(
            item.entry.timetablePeriod
              ? item.entry.timetablePeriod.startTime
              : item.entry.startDateTime
          ).format('HH:mm')}{' '}
          - {''}
          {moment(
            item.entry.timetablePeriod
              ? item.entry.timetablePeriod.endTime
              : item.entry.endDateTime
          ).format('HH:mm')}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          startIcon={<LaunchIcon />}
          onClick={() => router.push(`/cover/${item.id}/view`)}
        >
          View cover details
        </Button>
      </CardActions>
    </Card>
  );
}

export default function AllocatedCover({ items }) {
  const [isAvailabilityDialogOpen, setAvailabilityDialogOpen] = useState(false);
  return (
    <div style={{ margin: '25px 0' }}>
      <AvailabilityDialog
        isOpen={isAvailabilityDialogOpen}
        handleClose={() => setAvailabilityDialogOpen(false)}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          margin: '10px 0',
          gap: 5,
        }}
      >
        <Typography component="h2" variant="h5" style={{ fontWeight: 'bold' }}>
          Allocated Cover
        </Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            startIcon={<GetAppIcon />}
            onClick={() => exportMultiplePdfs(items)}
            disabled={!items?.length}
          >
            Export cover work
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<EventBusyIcon />}
            onClick={() => setAvailabilityDialogOpen(true)}
            style={{ marginLeft: 10 }}
          >
            Set availability
          </Button>
        </div>
      </div>
      {!items?.length && <EmptyState />}
      <div
        style={{
          display: 'flex',
          gap: 15,
          flexWrap: 'wrap',
        }}
      >
        {items?.map((item) => (
          <CoverCard key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}
