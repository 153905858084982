import moment from 'moment';
import { useState } from 'react';
import { useRouter } from 'next/router';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CancelCoverDialog from './CancelCoverDialog';
import checkIsWorkSet from '../utils/checkIsWorkSet';
import formatStaffName from '../utils/formatStaffName';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: 'calc(33.3% - 10px)',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 14,
  },
});

function CoverCard({ item, showCancelButton, showEditButton, showViewButton }) {
  const [isCancelDialogOpen, setCancelDialogOpen] = useState(false);
  const router = useRouter();
  const classes = useStyles();
  const isWorkSet = checkIsWorkSet(item?.content);
  const isCancelDisabled =
    item.assignedTo?.length > 0 || item.isManagerAssigned;
  return (
    <>
      <CancelCoverDialog
        item={item}
        isOpen={isCancelDialogOpen}
        handleClose={() => setCancelDialogOpen(false)}
        showCancelAll={!item.delegatedToId}
      />
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {moment(item.startDateTime).format('ddd DD MMM')}
            </Typography>
            <Chip
              variant="outlined"
              color={isWorkSet ? 'primary' : 'secondary'}
              size="small"
              icon={isWorkSet ? <CheckIcon /> : <CloseIcon />}
              label={isWorkSet ? 'Work set' : 'Work not set'}
            />
          </div>
          <Typography variant="h5" component="h2">
            {item.entry.class.name}
          </Typography>
          <Typography color="textSecondary">
            Period{' '}
            {item.entry.timetablePeriod
              ? item.entry.timetablePeriod.name.split(':')[1]
              : `${item.entry.startDateTime.toLocaleString()}
          - ${item.entry.endDateTime.toLocaleString()}`}{' '}
            &bull; {moment(item.startDateTime).format('HH:mm')} - {''}
            {moment(item.endDateTime).format('HH:mm')}
          </Typography>
          {/* show original staff member for delegated cover */}
          {item.requestedBy && (
            <Typography color="textSecondary" variant="body2">
              Delegated to you &middot; Usually taught by{' '}
              <abbr title={formatStaffName(item.requestedBy)}>
                {item.requestedBy.staffCode}
              </abbr>
            </Typography>
          )}
        </CardContent>
        <div style={{ flexGrow: 1 }} />
        <CardActions
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {showEditButton && (
            <Button
              size="small"
              startIcon={<EditIcon />}
              onClick={() => router.push(`/cover/${item.id}`)}
            >
              {`${isWorkSet ? 'Update' : 'Add'} cover work`}
            </Button>
          )}
          {showViewButton && (
            <Button
              size="small"
              startIcon={<DescriptionIcon />}
              onClick={() => router.push(`/cover/${item.id}/view`)}
            >
              View cover work
            </Button>
          )}
          {showCancelButton && (
            <div>
              <Tooltip
                title={
                  isCancelDisabled
                    ? 'You cannot cancel a request which has been assigned by your cover manager.'
                    : 'Cancel request'
                }
              >
                <span>
                  <IconButton
                    size="small"
                    onClick={() => setCancelDialogOpen(true)}
                    disabled={isCancelDisabled}
                  >
                    <ClearIcon
                      color={isCancelDisabled ? 'disabled' : 'secondary'}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          )}
        </CardActions>
      </Card>
    </>
  );
}

export default function CoverCardList({
  items = [],
  showCancelButton = true,
  showEditButton = true,
  showViewButton = false,
}) {
  return (
    <div
      style={{
        display: 'flex',
        gap: 15,
        flexWrap: 'wrap',
      }}
    >
      {items?.map((item) => (
        <CoverCard
          key={item.id}
          item={item}
          showCancelButton={showCancelButton}
          showEditButton={showEditButton}
          showViewButton={showViewButton}
        />
      ))}
    </div>
  );
}
