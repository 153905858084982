import { useQuery } from 'react-query';
import ApiError from '../utils/ApiError';

async function fetchDashboard() {
  const response = await fetch('/api/dashboard');
  const responseBody = await response.json();
  if (!response.ok) throw new ApiError(responseBody.message, response.status);
  return responseBody;
}

export default function useDashboard() {
  return useQuery('dashboard', fetchDashboard);
}
