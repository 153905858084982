import moment from 'moment';
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import LimitedBackdrop from './LimitedBackdrop';
import useDeleteRequest from '../hooks/useDeleteRequest';
import { stopBrowserBack } from '../utils/overrideBackButton';

export default function CancelCoverDialog({
  item,
  isOpen,
  handleClose,
  showCancelAll = false,
}) {
  const [cancelAll, setCancelAll] = useState(false);
  const {
    mutate,
    isLoading: isMutating,
    isSuccess,
    reset,
  } = useDeleteRequest();

  useEffect(() => {
    if (isSuccess) handleClose();
  }, [isSuccess]);

  const handleChange = (event) => setCancelAll(event.target.checked);

  const handleDelete = () =>
    mutate({ requestId: item.id, date: item.startDateTime, cancelAll });

  const onExitedModal = () => {
    reset();
    setCancelAll(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      TransitionProps={{
        onExited: () => onExitedModal(),
        onEntered: () => stopBrowserBack(handleClose),
      }}
    >
      <LimitedBackdrop open={isMutating}>
        <CircularProgress color="inherit" />
      </LimitedBackdrop>
      <DialogTitle id="form-dialog-title">Cancel Cover Request</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Are you sure you want to cancel your existing cover request for{' '}
              <strong>
                Period{' '}
                {item.entry.timetablePeriod
                  ? item.entry.timetablePeriod.name.split(':')[1]
                  : `${item.entry.startDateTime.toLocaleString()}
          - ${item.entry.endDateTime.toLocaleString()}`}{' '}
                ({item.entry.class.name})
              </strong>{' '}
              on{' '}
              <strong>
                {moment(item.startDateTime).format('dddd Do MMM, YYYY')}
              </strong>
              ?
            </Typography>
          </Grid>
          {showCancelAll && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cancelAll}
                    onChange={handleChange}
                    name="cancelAll"
                    color="secondary"
                  />
                }
                label="Cancel all cover requested on this date"
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isMutating}>
          &larr; Go back
        </Button>
        <Button onClick={handleDelete} color="secondary" disabled={isMutating}>
          Yes, cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
