import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import ArrowForward from '@material-ui/icons/ArrowForward';
import RequestedCover from '../components/RequestedCover';
import AllocatedCover from '../components/AllocatedCover';
import { useAuth } from '../components/AuthProvider';
import LoadingSpinner from '../components/LoadingSpinner';
import useDashboard from '../hooks/useDashboard';
import Stats from '../components/Stats';
import { actions, hasPermission } from '../lib/permissions';
import DepartmentalAbsences from '../components/DepartmentalAbsences';

function LineManagerReminder({ isVisible, handleClose }) {
  const router = useRouter();
  return (
    <Collapse in={isVisible}>
      <Alert severity="warning" style={{ marginTop: 20 }}>
        <AlertTitle>
          You haven&apos;t told us who your line manager is yet.
        </AlertTitle>
        If you are unable to set cover work, your line manager will usually be
        your first port of call for providing suitable cover work on your
        behalf.
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 10,
            marginTop: 10,
          }}
        >
          <Button
            onClick={() => {
              Cookies.set('hideLmReminder', 1, { expires: 1 });
              handleClose();
            }}
          >
            Remind me later
          </Button>
          <Button
            endIcon={<ArrowForward />}
            color="primary"
            onClick={() => router.push('/settings')}
          >
            Go to settings
          </Button>
        </div>
      </Alert>
    </Collapse>
  );
}

export default function Home() {
  const { user } = useAuth();
  const { isLoading, isError, data, error, isFetchedAfterMount } =
    useDashboard();
  const [isLineManagerReminderVisible, setLineManagerReminderVisible] =
    useState(false);

  const isManagerRequired = data?.school?.isManagerRequired;

  const requestedOrDelegatedCover = useMemo(() => {
    if (!data) return [];
    return [...data.requestedCover, ...data.delegatedCover].sort(
      (a, b) => new Date(a.startDateTime) - new Date(b.startDateTime)
    );
  }, [data]);

  useEffect(() => {
    if (!isFetchedAfterMount) return;
    setLineManagerReminderVisible(
      data?.hasLineManager &&
        !data?.lineManagerId &&
        isManagerRequired &&
        !Cookies.get('hideLmReminder')
    );
  }, [isFetchedAfterMount]);

  if (!user) return null;

  if (isLoading) return <LoadingSpinner />;

  if (isError) return <Typography>{error.message}</Typography>;

  return (
    <main>
      <Typography>
        You are logged in as{' '}
        <strong>{user.signInUserSession.idToken.payload.email}</strong>
      </Typography>
      <LineManagerReminder
        isVisible={isLineManagerReminderVisible}
        handleClose={() => setLineManagerReminderVisible(false)}
      />
      <RequestedCover
        items={requestedOrDelegatedCover}
        isManagerRequired={isManagerRequired}
      />
      {isManagerRequired && <AllocatedCover items={data.assignedCover} />}
      <DepartmentalAbsences items={data.departmentalAbsences} />
      {hasPermission(user, actions.VIEW_STATS) && <Stats />}
    </main>
  );
}
