import moment from 'moment';
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import LimitedBackdrop from './LimitedBackdrop';
import DatePicker from './DatePicker';
import useFrees from '../hooks/useFrees';
import useCreateAvailability from '../hooks/useCreateAvailability';
import { stopBrowserBack } from '../utils/overrideBackButton';

const initialState = {
  date: moment(),
  reason: '',
};

export default function AvailabilityDialog({ isOpen, handleClose }) {
  const [state, setState] = useState(initialState);
  const [selected, setSelected] = useState([]);
  const { isLoading, isError, data: frees, error } = useFrees(state.date);
  const {
    mutate,
    isLoading: isMutating,
    isSuccess,
    reset,
  } = useCreateAvailability();

  useEffect(() => {
    setSelected([]);
  }, [state.date]);

  useEffect(() => {
    if (isSuccess) handleClose();
  }, [isSuccess]);

  const handleChange = (event) =>
    setState({ ...state, [event.target.name]: event.target.value });

  const handleCreate = () => mutate({ ...state, periods: selected });

  const onExitedModal = () => {
    reset();
    setState(initialState);
    setSelected([]);
  };

  const isValid = () =>
    state.reason.length > 2 && state.date && selected.length;

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionProps={{
        onExited: () => onExitedModal(),
        onEntered: () => stopBrowserBack(handleClose),
      }}
    >
      <LimitedBackdrop open={isMutating}>
        <CircularProgress color="inherit" />
      </LimitedBackdrop>
      <DialogTitle id="form-dialog-title">Set availability</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Typography gutterBottom>
              Request not to be allocated cover on:
            </Typography>
            <DatePicker date={state.date} setState={setState} />
          </Grid>
          {state.date && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '10px 25px',
              }}
            >
              {isLoading && <Typography>Searching, please wait...</Typography>}
              {!isLoading && !frees?.length && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ErrorIcon style={{ marginRight: 10 }} />
                  <Typography>
                    No free periods found, try another date.
                  </Typography>
                </div>
              )}
              {frees?.length > 0 && (
                <>
                  <Typography>Please tick all that apply:</Typography>
                  {frees?.map((period) => (
                    <FormControlLabel
                      key={period.id}
                      control={
                        <Checkbox
                          checked={selected.includes(period.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelected([...selected, period.id]);
                            } else {
                              setSelected(
                                selected.filter(
                                  (_period) => period.id !== _period.id
                                )
                              );
                            }
                          }}
                        />
                      }
                      label={period.name}
                    />
                  ))}
                </>
              )}
            </div>
          )}
          <Grid item xs={12}>
            <TextField
              id="reason"
              name="reason"
              multiline
              rows={4}
              variant="outlined"
              placeholder="Provide a reason..."
              fullWidth
              value={state.reason}
              onChange={handleChange}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isMutating}>
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          color="primary"
          disabled={isMutating || !isValid()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
