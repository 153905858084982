import moment from 'moment';
import { useQuery } from 'react-query';
import ApiError from '../utils/ApiError';

async function fetchFreePeriods(date) {
  const query = new URLSearchParams();
  if (date) query.set('date', moment(date).format('YYYY-MM-DD'));
  const response = await fetch(`/api/frees?${query}`);
  const responseBody = await response.json();
  if (!response.ok) throw new ApiError(responseBody.message, response.status);
  return responseBody;
}

export default function useFrees(date) {
  return useQuery(['frees', date], () => fetchFreePeriods(date), {
    enabled: !!date,
  });
}
