import { useRouter } from 'next/router';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TimelineIcon from '@material-ui/icons/Timeline';
import formatStat from '../utils/formatStat';

export default function StatCard({ title, values, avatar, statKey }) {
  const router = useRouter();
  return (
    <Card variant="outlined">
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'h6' }}
        style={{ padding: '10px 15px' }}
        avatar={avatar}
      />
      <Divider />
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'row',
          textAlign: 'center',
        }}
      >
        <Grid container spacing={3} justify="center">
          {values.map((stat) => (
            <Grid
              item
              key={`${title}-${stat.subtitle}`}
              style={{ minWidth: 100 }}
            >
              <Typography variant="h6">{formatStat(stat.value)}</Typography>
              <Typography
                variant="overline"
                color="textSecondary"
                style={{ lineHeight: 0.5 }}
              >
                {stat.subtitle}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
      <Divider />
      <Typography>
        <Button
          fullWidth
          size="small"
          startIcon={<TimelineIcon />}
          color="primary"
          onClick={() =>
            router.push({ pathname: '/stats', query: { detail: statKey } })
          }
        >
          View analytics
        </Button>
      </Typography>
    </Card>
  );
}
