import moment from 'moment';
import { CoverReason } from '@prisma/client';
import { useEffect, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Alert from '@material-ui/lab/Alert';
import { colors } from '@material-ui/core';
import LimitedBackdrop from './LimitedBackdrop';
import DateRangePicker from './DateRangePicker';
import useTimetable from '../hooks/useTimetable';
import LoadingSpinner from './LoadingSpinner';
import useCreateCover from '../hooks/useCreateCover';
import StaffSelect from './StaffSelect';
import { stopBrowserBack } from '../utils/overrideBackButton';

const initialState = {
  startDate: null,
  endDate: null,
  isFuture: 'false',
  isIncapacitated: false,
  reason: CoverReason.SICKNESS,
  authorisedBy: '',
};

export default function CoverRequestDialog({
  isOpen,
  handleClose,
  isManagerRequired,
}) {
  const [state, setState] = useState(initialState);
  const [selected, setSelected] = useState([]);
  const { mutate, isLoading: isMutating, isSuccess, reset } = useCreateCover();
  const { isLoading, isError, data, error } = useTimetable(state);
  const today = new Date().toLocaleDateString();
  const selectedEntryIds = useMemo(() => {
    return selected.map((entry) => entry.id);
  }, [selected]);
  const allEntriesRequiringCover = useMemo(() => {
    return data?.entries?.filter((entry) => entry.class) ?? [];
  }, [data]);
  const selectedEntriesRequiringCover = useMemo(() => {
    return selected.filter((entry) => entry.class);
  }, [selected]);

  useEffect(() => {
    setState({
      ...state,
      startDate: null,
      endDate: null,
    });
  }, [state.isFuture]);

  useEffect(() => {
    if (!state.isIncapacitated)
      setSelected(selected.map(({ delegatedTo, ...item }) => item));
  }, [state.isIncapacitated]);

  useEffect(() => {
    if (data) setSelected(data.entries);
  }, [data]);

  useEffect(() => {
    if (state.isIncapacitated || state.reason === CoverReason.SICKNESS) {
      setState({
        ...state,
        reason: CoverReason.SICKNESS,
        authorisedBy: '',
      });
    }
  }, [state.reason, state.isIncapacitated]);

  useEffect(() => {
    if (isSuccess) handleClose();
  }, [isSuccess]);

  const handleChange = (event) =>
    setState({ ...state, [event.target.name]: event.target.value });

  const handleCreate = () =>
    mutate({
      ...state,
      entries: state.isIncapacitated
        ? selected.map((entry) => ({
            ...entry,
            delegatedTo: entry.delegatedTo ?? data.lineManager,
          }))
        : selected,
    });

  const onExitedModal = () => {
    reset();
    setState(initialState);
    setSelected(data?.entries ?? []);
  };

  const isValid = () => {
    if (
      state.isIncapacitated &&
      !data?.lineManager &&
      selectedEntriesRequiringCover.length !==
        selected.filter((entry) => entry.delegatedTo).length
    )
      return false;

    if (state.isFuture === 'true' && !(state.startDate && state.endDate))
      return false;

    return selectedEntriesRequiringCover.length;
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionProps={{
        onExited: () => onExitedModal(),
        onEntered: () => stopBrowserBack(handleClose),
      }}
    >
      <LimitedBackdrop open={isMutating}>
        <CircularProgress color="inherit" />
      </LimitedBackdrop>
      <DialogTitle id="form-dialog-title">New Cover Request</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <FormControl component="fieldset" required>
              <FormLabel component="legend">
                I am requesting cover for...
              </FormLabel>
              <RadioGroup
                aria-label="type"
                name="isFuture"
                value={state.isFuture}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Today"
                />
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Select date(s)"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {state.isFuture === 'true' && (
            <Grid item xs={12}>
              <DateRangePicker
                startDate={state.startDate}
                endDate={state.endDate}
                setState={setState}
              />
            </Grid>
          )}
          {(state.isFuture === 'false' ||
            (state.startDate && state.endDate)) && (
            <Grid item xs={12}>
              <Alert severity="info">
                {state.isFuture === 'true' ? (
                  <>
                    <Typography>
                      We found{' '}
                      <strong>{allEntriesRequiringCover.length}</strong>{' '}
                      lesson(s) requiring cover between{' '}
                      <strong>
                        {new Date(state.startDate).toLocaleDateString()}
                      </strong>{' '}
                      and{' '}
                      <strong>
                        {new Date(state.endDate).toLocaleDateString()}
                      </strong>
                      .{' '}
                    </Typography>
                    {data?.entries?.length > 0 && (
                      <Typography style={{ fontWeight: 700, marginTop: 10 }}>
                        Please select all periods that you will not be available
                        for:
                      </Typography>
                    )}
                  </>
                ) : (
                  <>
                    <Typography>
                      We found{' '}
                      <strong>{allEntriesRequiringCover.length}</strong>{' '}
                      lesson(s) requiring cover on <strong>{today}</strong>.
                    </Typography>
                    {data?.entries?.length > 0 && (
                      <Typography style={{ fontWeight: 700, marginTop: 10 }}>
                        Please select all periods that you will not be available
                        for:
                      </Typography>
                    )}
                  </>
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: data?.entries?.length > 0 ? '10px 25px' : 0,
                  }}
                >
                  {data?.entries?.map((entry) => {
                    const isSelected = selectedEntryIds.includes(entry.id);
                    const formattedDatePeriod = `${moment(
                      entry.startDateTime
                    ).format('ddd DD MMM')} - Period ${
                      entry.timetablePeriod
                        ? entry.timetablePeriod.name.split(':')[1]
                        : `${entry.startDateTime.toLocaleString()}
          - ${entry.endDateTime.toLocaleString()}`
                    }`;
                    return (
                      <FormControlLabel
                        key={entry.id}
                        control={
                          <Checkbox
                            checked={isSelected}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelected([...selected, entry]);
                              } else {
                                setSelected(
                                  selected.filter(
                                    (_entry) => entry.id !== _entry.id
                                  )
                                );
                              }
                            }}
                          />
                        }
                        label={
                          entry.class
                            ? `${formattedDatePeriod} - ${entry.class.name}`
                            : `${formattedDatePeriod} - ${
                                isSelected
                                  ? 'No cover required'
                                  : 'Available to provide cover'
                              }`
                        }
                        style={{
                          fontStyle: entry.class ? 'normal' : 'italic',
                          color: !entry.class
                            ? isSelected
                              ? colors.red[800]
                              : colors.green[800]
                            : 'inherit',
                        }}
                      />
                    );
                  })}
                </div>
                {data?.entries?.length > 0 && (
                  <Typography>
                    If you are able, we will prompt you to set cover work for
                    each of these lessons after submitting your request.
                  </Typography>
                )}
              </Alert>
              {isLoading && <LoadingSpinner />}
            </Grid>
          )}
          {isManagerRequired && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.isIncapacitated}
                    onChange={(e) =>
                      setState({
                        ...state,
                        isIncapacitated: e.target.checked,
                      })
                    }
                    name="isIncapacitated"
                  />
                }
                label={
                  <Typography>
                    I am unable to set cover work (e.g. health reasons)
                  </Typography>
                }
              />
            </Grid>
          )}
          {state.isIncapacitated && selectedEntriesRequiringCover.length > 0 && (
            <Grid item xs={12}>
              <Typography style={{ fontWeight: 700 }}>
                Please tell us who you would like to set cover work on your
                behalf:
              </Typography>
              {selected
                .filter((entry) => entry.class)
                .map((entry) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      key={entry.id}
                      style={{ margin: '10px 0' }}
                    >
                      <Typography
                        style={{ marginBottom: 10, fontStyle: 'italic' }}
                      >{`${moment(entry.startDateTime).format(
                        'ddd DD MMM'
                      )} - Period ${
                        entry.timetablePeriod
                          ? entry.timetablePeriod.name.split(':')[1]
                          : `${entry.startDateTime.toLocaleString()}
          - ${entry.endDateTime.toLocaleString()}`
                      } - ${entry.class.name}`}</Typography>
                      <StaffSelect
                        value={entry.delegatedTo || data?.lineManager}
                        onChange={(newValue) =>
                          setSelected((prevSelected) =>
                            prevSelected.map((_entry) =>
                              _entry.id === entry.id
                                ? { ...entry, delegatedTo: newValue }
                                : _entry
                            )
                          )
                        }
                        onChangeAll={(newValue) =>
                          setSelected((prevSelected) =>
                            prevSelected.map((entry) =>
                              entry.class
                                ? {
                                    ...entry,
                                    delegatedTo: newValue,
                                  }
                                : entry
                            )
                          )
                        }
                      />
                    </Grid>
                  );
                })}
            </Grid>
          )}
          {!state.isIncapacitated && (
            <>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <FormControl fullWidth>
                  <InputLabel id="reason-label">Reason for absence</InputLabel>
                  <Select
                    labelId="reason-label"
                    id="reason"
                    name="reason"
                    value={state.reason}
                    onChange={handleChange}
                  >
                    {Object.keys(CoverReason).map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {state.reason === CoverReason.OTHER && (
                <Grid item xs={12} sm={8} md={9} lg={10}>
                  <TextField
                    id="authorisedBy"
                    name="authorisedBy"
                    label="Reason / authorised by"
                    fullWidth
                    autoFocus
                    value={state.authorisedBy}
                    onChange={handleChange}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isMutating}>
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          color="primary"
          disabled={isMutating || !isValid()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
