import { useState } from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import HelpOutline from '@material-ui/icons/HelpOutline';
import CoverCardList from './CoverCardList';
import DepartmentalAbsencesHelpDialog from './DepartmentalAbsencesHelpDialog';

export default function DepartmentalAbsences({ items }) {
  const [isHelpDialogOpen, setHelpDialogOpen] = useState(false);
  if (!items?.length) return null;
  return (
    <>
      <DepartmentalAbsencesHelpDialog
        open={isHelpDialogOpen}
        handleClose={() => setHelpDialogOpen(false)}
      />
      <div style={{ margin: '25px 0' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            margin: '10px 0',
            gap: 5,
          }}
        >
          <Typography
            component="h2"
            variant="h5"
            style={{ fontWeight: 'bold' }}
          >
            Departmental Absences
          </Typography>
          <Link
            variant="body1"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 5,
              cursor: 'pointer',
            }}
            onClick={() => setHelpDialogOpen(true)}
          >
            <HelpOutline /> Why am I seeing this?
          </Link>
        </div>
        <CoverCardList
          items={items}
          showViewButton={true}
          showEditButton={false}
          showCancelButton={false}
        />
      </div>
    </>
  );
}
