import { useQuery } from 'react-query';
import ApiError from '../utils/ApiError';

async function fetchStats() {
  const response = await fetch('/api/stats');
  const responseBody = await response.json();
  if (!response.ok) throw new ApiError(responseBody.message, response.status);
  return responseBody;
}

export default function useStats() {
  return useQuery('stats', fetchStats);
}
